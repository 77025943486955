// Generated by Framer (bdd6aa1)

import { addFonts, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {ZhNOUTrLi: {hover: true}};

const cycleOrder = ["ZhNOUTrLi"];

const variantClassNames = {ZhNOUTrLi: "framer-v-1g2sav4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZhNOUTrLi", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-L8JMW", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "UCMBzBmWX"}}><motion.a {...restProps} className={`${cx("framer-1g2sav4", className)} framer-mm5r71`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZhNOUTrLi"} ref={ref} style={{...style}} {...addPropertyOverrides({"ZhNOUTrLi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Termékek</motion.p></React.Fragment>} className={"framer-q02beu"} layoutDependency={layoutDependency} layoutId={"MeFcyUb1XWL9ivN9gM"} style={{"--framer-paragraph-spacing": "0px", textShadow: "none"}} transformTemplate={transformTemplate} variants={{"ZhNOUTrLi-hover": {textShadow: "0px 0px 4px #6678FF"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-L8JMW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-L8JMW .framer-mm5r71 { display: block; }", ".framer-L8JMW .framer-1g2sav4 { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 77px; }", ".framer-L8JMW .framer-q02beu { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-L8JMW .framer-v-1g2sav4 .framer-1g2sav4 { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 77
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"qXApgK2WS":{"layout":["fixed","fixed"]}}}
 */
const FrameravFoPESGh: React.ComponentType<Props> = withCSS(Component, css, "framer-L8JMW") as typeof Component;
export default FrameravFoPESGh;

FrameravFoPESGh.displayName = "Terméke";

FrameravFoPESGh.defaultProps = {height: 32, width: 77};

addFonts(FrameravFoPESGh, [...sharedStyle.fonts])